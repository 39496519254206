/**
 * キーダウンイベント処理
 */
window.onKeyDown = function () {
    // Enter キーの時だけ
    if (event.which === 13) {
        // これ以降のイベントはキャンセルしておく
        event.stopImmediatePropagation();
        event.preventDefault();

        // ログイン Submit
        document.getElementById('loginForm').submit();
    }
}

/**
 * ログイン処理
 */
window.loginProcess = function () {
    // ボタン非活性
    $('#login').prop('disabled', true);
    // ローディング切替
    $('#login i').hide();
    $('#login div').show();
    // ログイン処理
    $('#loginForm').submit();
}
